import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';

class IndexPage extends React.Component {
  render() {
    const siteTitle = 'Gatsby Starter Personal Website';

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            'blog',
            'javascript',
            'react',
            'graphql',
            'docker',
            'postgres',
          ]}
        />
        {/* <img style={{ margin: 0 }} src="./GatsbyScene.svg" alt="Gatsby Scene" /> */}
        <h2>
          Hi!{' '}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h2>
        <h1
          style={{
            // marginBottom: rhythm(1 / 4),
            fontSize: '4em',
            fontFamily: `Merriweather, sans-serif`,
          }}
        >
          I’m Chris.
          <br />I build things for the web.
        </h1>
        {/* <p>Welcome to your new Gatsby website. You are on your home page.</p>
        <p>
          This starter comes out of the box with styled components and Gatsby's
          default starter blog running on Netlify CMS.
        </p>
        <p>Now go build something great!</p> */}
        {/* <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link> */}
      </Layout>
    );
  }
}

export default IndexPage;
